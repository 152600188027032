<template>
    <span :title="certInfo + fileInfo" >
        <!-- <iconSignedSvg :class="getCertIconClass()" v-if="file.signature" />{{ getTestText() }} -->
    </span>
</template>
<script>
import iconSignedSvg from "../../assets/icons/svg/certificate.svg";
import { mapActions, mapGetters } from "vuex";

export default {
    components: { iconSignedSvg },
    name: 'Cert',
    props:{
        file: Object,
        id2: String
    },
    data: () => ({
        certInfo: 'Проверка файла...',
        fileInfo: '',
        certStatus: 0
	}),
	computed: {
        ...mapGetters({
            apiUrl: 'apiUrl'
        }),
    },
	methods: {
		...mapActions({
			apiAll: "api/apiAll"
		}),
		getCertIconClass () {
			if(this.certStatus < 0)
				return "b-icon b-icon--file icon-file b-icon--invalidsert";
            if(this.certStatus > 0)
			    return "b-icon b-icon--file icon-file b-icon--validsert";
            return "b-icon b-icon--file icon-file b-icon--unknownsert";
		},
		getTestText () {
            let s = '';
			// if(this.file.signature)
			// 	s+= 's: +';
            // else
            //     s+= 's: -';
            // if(this.id)
            //     s+= 'id: '+this.id;
            //     else
            //      s+= 'id: -';
            // if(this.id2)
            //     s+= 'id: '+this.id2;
            //     else
            //      s+= 'id: -';
			// if(this.file.id)
			//  	s+= ', fileid: '+this.file.id;
            //  else
            //      s+= ', fileid: -';
			// if(this.file.signature)
			// 	s+= ', s: ' + this.file.signature.length;
            // else
            //     s+= ', s: -';
            return s;
		},
		updateCertInfo () {
			console.debug('updateCertInfo( ' + this.getTestText() + ')');
            // if(this.file.signature && !this.certRead){
            //     let signature = this.file.signature;
            //     this.$root.$refs.A.get_signature_info_async(signature).then(info => {
            //         //this.certStatus = 1;
            //         this.certInfo = 'Файл подписан ' + info.time + ' сертификатом ' + info.signer + '.';
            //         console.debug('112', info);
            //     }, function(err){
            //         //this.certStatus = -1;
            //         this.certInfo = 'Ошибка чтения подписи: ' + err;
            //     });
            // }
            //console.debug('id', this.id)
            // console.log('file.id', this.file)
            if(this.file && (!this.file.id || this.file.id === 'newZ')){
                this.certStatus = 1;
                this.certInfo = 'Файл только что подписан.';
            }else{
                if(this.file && (this.file.id && this.file.signature)){
                    this.downloadFileContent();
                } 
                 else{
                    console.debug('file.signature is null' + this.getTestText())
                //     // объект еще не полностью заполнен
                //     setTimeout(() => {
                //         this.updateCertInfo();
                //     }, 100)
                }
            }
		},
        downloadFileContent(){
            console.debug('downloadFileContent: ' + this.getTestText());
            let url = `${this.apiUrl}api/ProjectFiles?id=${this.file.id}`;
            let signature = this.file.signature;

            if(this.$root.$refs.A) {
                this.$root.$refs.A.verify_file_async(signature, url).then(info => {
                    this.certStatus = 1;
                    this.certInfo = 'Файл подписан ' + info.time + ' сертификатом ' + info.signer + '.';
                    console.debug('115', info);
                }, err => {
                    console.debug('116', err);
                    this.certStatus = -1;
                    this.certInfo = 'Файл был изменен!';
                });
            }

            // this.apiAll({type: 'get', url: url, responseType: 'arraybuffer'}).then(response => {
            //     //console.debug('file downloaded: ', response.data);
            //     //let b64 = Buffer.from(response.data, 'base64');
            //     let signature = this.file.signature;
            //     //console.debug('file: ', this.file.id);
            //     //console.debug('b64: ', b64);
            //     this.$root.$refs.A.verify_file_async(signature, response.data).then(info => {
            //         this.certStatus = 1;
            //         this.certInfo = 'Файл подписан ' + info.time + ' сертификатом ' + info.signer + '.';
            //         console.debug('115', info);
            //     }, err => {
            //         console.debug('116', err);
            //         this.certStatus = -1;
            //         this.certInfo = 'Файл был изменен!';
            //     });
            // })
        },
        downloadFileContent2(){
            let url = `api/ProjectFiles?id=${this.file.id}`;
            //console.debug('downloadFileContent(' + url + ')');
            let self = this;
            this.apiAll({type: 'get', url: url}).then(response => {
                //console.debug('file downloaded: ', response.data);
                let signature = this.file.signature;
                //console.debug('signature: ', signature);
                if(this.file.id == 2559){
                    self.certStatus = -1;
                    self.fileInfo = ' Файл был изменен!';
                }
                else{
                    self.certStatus = 1;
                    self.fileInfo = '';
                }
            })
        }
    },
    watch: {
        file: {
            handler: function (val, oldVal) {
                console.debug('file changed. ', this.getTestText());
                this.updateCertInfo();
            },
            deep: true
        }
    },
	mounted() {
		this.updateCertInfo();
	},
}
</script>